import { graphql, useStaticQuery } from 'gatsby';

type NavItem = {
  id: string;
  footerOnly: boolean | null;
  label: string;
  route: {
    current: string;
  };
};

type NavItems = Array<NavItem>;

type SanityNavigation = { navItems: NavItems };

type SanityNavigationResult = { sanityNavigation: SanityNavigation };

function useSanityNavigation(): NavItems {
  const { sanityNavigation } = useStaticQuery<SanityNavigationResult>(graphql`
    query SanitySiteNavigationQuery {
      sanityNavigation {
        navItems {
          id: _key
          footerOnly
          label
          route {
            current
          }
        }
      }
    }
  `);
  return sanityNavigation.navItems;
}

export { useSanityNavigation };
export type { NavItem, NavItems, SanityNavigation, SanityNavigationResult };
