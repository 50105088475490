import * as React from 'react';

function Wave(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 1440 96" {...props}>
      <path d="M1440 64l-48-16c-48-16-144-48-240-48S960 32 864 53.3C768 75 672 85 576 74.7 480 64 384 32 288 32S96 64 48 80L0 96h1440V64z" />
    </svg>
  );
}

export { Wave };
