import * as React from 'react';

type ButtonProps = {
  children: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  variant?: 'teal' | 'green' | 'brown' | 'brownLight';
  onClick?: (...p: unknown[]) => unknown;
  props?: unknown[];
};

const VARIANT_MAP = {
  teal: 'bg-teal',
  green: 'bg-green',
  brown: 'bg-brown',
  brownLight: 'bg-brown-light',
};

function Button({
  children,
  type = 'button',
  variant = 'teal',
  onClick,
  ...props
}: ButtonProps): React.ReactElement {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      className={`${VARIANT_MAP[variant]} inline-flex items-center px-6 py-3 text-base font-semibold text-white border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2`}
      {...props}
    >
      {children}
    </button>
  );
}

export { Button };
