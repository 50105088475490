import { graphql, useStaticQuery } from 'gatsby';

type Address = {
  googleMaps: {
    embed: string;
    link: string;
  };
  streetAddress: string;
  suburb: string;
};

type Hours = Array<{
  id: string;
  days: string;
  hours: string;
}>;

type ShareImage = {
  asset: {
    url: string;
  };
};

type SocialLinks = Array<{
  id: string;
  link: string;
  socialNetwork: 'Facebook' | 'Instagram' | 'LinkedIn' | 'Twitter';
}>;

type SanitySiteSettings = {
  address: Address;
  description: string;
  email: string;
  hours: Hours;
  phoneNumber: string;
  shareImage: ShareImage;
  socialLinks: SocialLinks;
  siteUrl: string;
  title: string;
};

type SanitySiteSettingsResult = {
  sanitySiteSettings: SanitySiteSettings;
};

function useSanitySiteSettings(): SanitySiteSettings {
  const {
    sanitySiteSettings,
  } = useStaticQuery<SanitySiteSettingsResult>(graphql`
    query SanitySiteSettingsQuery {
      sanitySiteSettings {
        address {
          googleMaps {
            embed
            link
          }
          streetAddress
          suburb
        }
        description
        email
        hours {
          id: _key
          days
          hours
        }
        phoneNumber
        shareImage {
          asset {
            url
          }
        }
        siteUrl
        socialLinks {
          id: _key
          link
          socialNetwork
        }
        title
      }
    }
  `);
  return sanitySiteSettings;
}

export { useSanitySiteSettings };
export type {
  Address,
  Hours,
  SanitySiteSettings,
  SanitySiteSettingsResult,
  ShareImage,
  SocialLinks,
};
