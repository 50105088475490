import * as React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

type InputProps = {
  name: string;
  label: string;
  type?: 'text' | 'email' | 'number' | 'password' | 'search' | 'tel';
  errors: DeepMap<Record<string, unknown>, FieldError>;
  props?: unknown[];
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ name, label, type = 'text', errors, ...props }, ref) => {
    const hasErrors = Boolean(errors?.[name]);
    return (
      <div>
        <label htmlFor={name} className="block">
          <span className="sr-only">{label}</span>
          <input
            ref={ref}
            id={name}
            name={name}
            type={type}
            placeholder={label}
            aria-invalid={hasErrors}
            className="block w-full border-transparent focus:ring-blue-500 focus:ring-opacity-50 focus:border-blue-500 focus:border-opacity-50"
            {...props}
          />
        </label>
        {hasErrors ? (
          <div
            role="alert"
            className="mt-1 text-xs font-semibold tracking-widest text-red-800 uppercase"
          >
            {label} is a required field
          </div>
        ) : null}
      </div>
    );
  }
);

export { Input };
