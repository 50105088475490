import { CategoryIcon } from '../icons/category';
import { CloseIcon } from '../icons/close';
import { FacebookIcon } from '../icons/facebook';
import { InstagramIcon } from '../icons/instagram';
import { TwitterIcon } from '../icons/twitter';

export const ICON_MAP = {
  Facebook: FacebookIcon,
  Instagram: InstagramIcon,
  Twitter: TwitterIcon,
  Category: CategoryIcon,
  Close: CloseIcon,
};
