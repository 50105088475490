import * as React from 'react';

import { Wave } from '../icons/wave';
import { NavRooms } from './nav-rooms';

type HeroProps = {
  image: React.ReactNode;
  heading: React.ReactNode;
  cta: React.ReactNode;
  waveColor?: string;
};

function Hero({
  image,
  heading,
  cta,
  waveColor = '',
}: HeroProps): React.ReactElement {
  return (
    <header className="relative w-full mx-auto text-white bg-brown max-w-screen-2xl">
      <div className="absolute inset-0 flex">{image}</div>
      <div className="relative bg-black bg-opacity-25">
        <div className="relative w-full max-w-screen-lg px-4 py-24 mx-auto sm:px-6 lg:px-8">
          {heading}
          <p className="mt-12">{cta}</p>
        </div>
      </div>

      <Wave className={`absolute inset-x-0 bottom-0 z-10 ${waveColor}`} />
      <div className="absolute inset-y-0 right-0 z-10 h-full py-4">
        <NavRooms />
      </div>
    </header>
  );
}

export { Hero };
