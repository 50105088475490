import * as React from 'react';
import { useForm } from 'react-hook-form';

import { Input } from './form-elements/input';
import { NetlifyForm } from './form-elements/netlify-form';
import { Textarea } from './form-elements/textarea';
import { Button } from './ui/button';

type FormProps = {
  submitColor?: 'teal' | 'green' | 'brown' | 'brownLight';
  submitAlignment?: 'left' | 'center' | 'right';
};

type FormData = {
  full_name: string;
  email_address: string;
  contact_number: string;
  subject: string;
  message: string;
};

const ALIGNMENT_MAP = {
  left: 'md:text-left',
  center: 'md:text-center',
  right: 'md:text-right',
};

function ContactForm({
  submitColor = 'green',
  submitAlignment = 'left',
}: FormProps): React.ReactElement {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onBlur', reValidateMode: 'onChange' });
  return (
    <NetlifyForm handleSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-6">
        <Input
          label="Full name"
          {...register('full_name', { required: true })}
          errors={errors}
        />
        <Input
          label="Email address"
          {...register('email_address', { required: true })}
          errors={errors}
        />
        <Input
          label="Contact number"
          {...register('contact_number', { required: true })}
          errors={errors}
        />
        <Input
          label="Subject"
          {...register('subject', { required: true })}
          errors={errors}
        />
        <Textarea
          label="Message"
          {...register('message', { required: true })}
          errors={errors}
        />
        <p className={`text-center ${ALIGNMENT_MAP[submitAlignment]}`}>
          <Button type="submit" variant={submitColor}>
            Submit
          </Button>
        </p>
      </div>
    </NetlifyForm>
  );
}

export { ContactForm };
