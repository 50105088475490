import { Link } from 'gatsby';
import * as React from 'react';

import { useSanityNavigation } from '../hooks/use-sanity-site-navigation';
import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';
import { Logo } from '../icons/logo';
import { ICON_MAP } from '../lib/icon-map';

function Footer(): React.ReactElement {
  const navItems = useSanityNavigation();
  const config = useSanitySiteSettings();
  return (
    <footer className="bg-white" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-screen-lg px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8 text-brown">
        <div className="grid gap-8 lg:grid-cols-2">
          <div>
            <Link
              to="/"
              className="block max-w-xs transform lg:-translate-y-16"
            >
              <Logo />
            </Link>
          </div>
          <div className="grid gap-8 md:grid-cols-5">
            <div className="md:col-span-2">
              <nav className="space-y-4 text-brown">
                {navItems.map(({ id, route, label }) => (
                  <Link
                    key={id}
                    to={route?.current ? `/${route.current}` : '/'}
                    className="block hover:underline"
                  >
                    {label}
                  </Link>
                ))}
              </nav>
            </div>
            <div className="md:col-span-3">
              <dl className="space-y-4 text-brown">
                <div>
                  <dt className="inline font-semibold">Phone: </dt>
                  <dd className="inline">
                    <a
                      href={`tel:${config.phoneNumber}`}
                      className="hover:underline"
                    >
                      {config.phoneNumber}
                    </a>
                  </dd>
                </div>
                <div>
                  <dt className="inline font-semibold">Email: </dt>
                  <dd className="inline">
                    <a
                      href={`mailto:${config.email}`}
                      className="hover:underline"
                    >
                      {config.email}
                    </a>
                  </dd>
                </div>
                <div>
                  <dt className="inline font-semibold">Office Hours: </dt>
                  <dd className="inline">
                    {config.hours.map(({ id, days, hours }) => (
                      <React.Fragment key={id}>
                        <span className="inline-block">{days}</span>
                        <span className="inline-block">{hours}</span>
                      </React.Fragment>
                    ))}
                  </dd>
                </div>
                <div>
                  <dt className="inline font-semibold">Address: </dt>
                  <dd className="inline">
                    <a
                      href={`mailto:${config.address.googleMaps.link}`}
                      className="hover:underline group"
                    >
                      {config.address.streetAddress},{' '}
                      <span className="inline-block group-hover:underline">
                        {config.address.suburb}
                      </span>
                    </a>
                  </dd>
                </div>
              </dl>
              {config.socialLinks.map(({ id, link, socialNetwork }) => {
                const Icon = ICON_MAP[socialNetwork];
                return (
                  <a
                    key={id}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block mt-4 transition duration-150 ease-in-out hover:opacity-75"
                  >
                    <span className="sr-only">{socialNetwork}</span>
                    <Icon aria-hidden className="w-6 h-6" />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-gray-200">
          <p className="text-base font-medium text-right uppercase">
            <a
              href="https://www.phirannodesigns.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website by Phiranno Designs
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
