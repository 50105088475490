import { graphql, useStaticQuery } from 'gatsby';

type Room = {
  id: string;
  title: string;
  slug: {
    current: string;
  };
};

type Rooms = Array<Room>;

type SanityRooms = { rooms: Rooms };

type SanityRoomsResult = { sanityRooms: SanityRooms };

function useSanityRooms(): Rooms {
  const { sanityRooms } = useStaticQuery<SanityRoomsResult>(graphql`
    query SanitySiteRoomsQuery {
      sanityRooms {
        rooms {
          id: _key
          title
          slug {
            current
          }
        }
      }
    }
  `);
  return sanityRooms.rooms;
}

export { useSanityRooms };
export type { Room, Rooms, SanityRooms, SanityRoomsResult };
