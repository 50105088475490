import { Transition } from '@headlessui/react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import * as React from 'react';
import { HiMenu } from 'react-icons/hi';
import OutsideClickHandler from 'react-outside-click-handler';

import { useSanityNavigation } from '../hooks/use-sanity-site-navigation';
import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';
import { Logo } from '../icons/logo';

function Nav(): React.ReactElement {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const close = (): void => setIsOpen(false);
  const navItems = useSanityNavigation();
  const config = useSanitySiteSettings();
  return (
    <div className="sticky inset-x-0 top-0 z-20 bg-white">
      <div className="relative">
        <div className="flex items-center justify-between max-w-screen-lg px-4 py-5 mx-auto sm:px-6 sm:py-4 lg:px-8 lg:justify-start lg:space-x-10">
          <div>
            <Link to="/" className="flex">
              <span className="sr-only">{config.title}</span>
              <Logo className="w-auto h-14 md:h-24" />
            </Link>
          </div>
          <div className="-my-2 -mr-2 lg:hidden">
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span className="sr-only">Open menu</span>
              <HiMenu aria-hidden className="w-6 h-6" />
            </button>
          </div>
          <div className="hidden space-y-4 lg:flex-col lg:items-end lg:flex-1 lg:flex">
            <a
              href={`tel:${config.phoneNumber}`}
              className="inline-block px-6 py-1 font-medium text-white rounded-full bg-teal"
            >
              {config.phoneNumber}
            </a>
            <div className="lg:flex lg:items-center lg:justify-between">
              <nav className="flex justify-end ml-auto space-x-10">
                {navItems.map(({ id, label, route }) => (
                  <Link
                    key={id}
                    to={route?.current ? `/${route.current}/` : '/'}
                    className={`text-base font-medium ${
                      pathname === route?.current
                        ? 'text-gray-900'
                        : 'text-gray-500'
                    } hover:text-gray-900`}
                  >
                    {label}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu isOpen={isOpen} close={close} />
    </div>
  );
}

type MobileMenuProps = { isOpen: boolean; close: () => void };

function MobileMenu({ isOpen, close }: MobileMenuProps): React.ReactElement {
  const navItems = useSanityNavigation();
  const config = useSanitySiteSettings();

  const handleEscape = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    },
    [close]
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          close();
        }
      });
    }
    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [close, handleEscape]);

  return (
    <OutsideClickHandler onOutsideClick={close}>
      <Transition
        show={isOpen}
        enterFrom="duration-200 ease-out"
        enter="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top-right transform lg:hidden"
      >
        <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
          <div className="px-5 pt-5 pb-6 sm:pb-8">
            <div className="flex items-center justify-between">
              <Link to="/" className="flex">
                <span className="sr-only">{config.title}</span>
                <Logo className="w-auto h-16" />
              </Link>
              <div className="-mr-2">
                <button
                  type="button"
                  onClick={close}
                  className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:ring-opacity-50 focus:border-blue-500 focus:border-opacity-50 "
                >
                  <span className="sr-only">Close menu</span>
                  {/* Heroicon name: outline/x */}
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="px-5 py-6">
            <nav className="grid grid-cols-2 gap-4">
              {navItems.map(({ id, route, label }) => (
                <Link
                  key={id}
                  to={route?.current ? `/${route.current}/` : '/'}
                  className="px-2 py-2 text-base font-medium text-gray-900 rounded-md hover:text-graydelay-700"
                >
                  {label}
                </Link>
              ))}
            </nav>
            <div className="mt-6">
              <a
                href={`tel:${config.phoneNumber}`}
                className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-teal"
              >
                Call: {config.phoneNumber}
              </a>
            </div>
          </div>
        </div>
      </Transition>
    </OutsideClickHandler>
  );
}

export { Nav };
