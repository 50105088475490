import * as React from 'react';

function CloseIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 26 26" {...props}>
      <path d="M24.981 20.066l-7.066-7.062 7.062-7.063a3.472 3.472 0 000-4.913 3.464 3.464 0 00-4.913-.004l-7.066 7.062-7.065-7.07a3.483 3.483 0 00-4.917 0 3.484 3.484 0 000 4.917l7.07 7.07-7.054 7.05a3.472 3.472 0 000 4.914 3.442 3.442 0 002.454 1.021c.894 0 1.78-.337 2.459-1.017l7.053-7.054 7.07 7.066A3.455 3.455 0 0022.523 26a3.476 3.476 0 002.459-5.934z" />
    </svg>
  );
}

export { CloseIcon };
