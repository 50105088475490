import * as React from 'react';

import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';
import { ICON_MAP } from '../lib/icon-map';

function ContactDetails(): React.ReactElement {
  const config = useSanitySiteSettings();

  return (
    <div className="space-y-8 text-brown">
      <div>
        <h3 className="text-xl font-semibold">Contact Details</h3>
        <dl className="space-y-4">
          <div>
            <dt className="sr-only">Phone: </dt>
            <dd className="inline">
              <a href={`tel:${config.phoneNumber}`} className="hover:underline">
                {config.phoneNumber}
              </a>
            </dd>
          </div>
          <div>
            <dt className="inline">Email: </dt>
            <dd className="inline">
              <a href={`mailto:${config.email}`} className="hover:underline">
                {config.email}
              </a>
            </dd>
          </div>
        </dl>
      </div>
      <div>
        <h3 className="text-xl font-semibold">Open Hours</h3>
        <dl>
          <div>
            <dt className="sr-only">Office Hours: </dt>
            <dd className="inline">
              {config.hours.map(({ id, days, hours }) => (
                <React.Fragment key={id}>
                  <span className="inline-block">{days}</span>{' '}
                  <span className="inline-block">{hours}</span>
                </React.Fragment>
              ))}
            </dd>
          </div>
        </dl>
      </div>
      <div>
        <h3 className="text-xl font-semibold">Find Us At</h3>
        <dl>
          <div>
            <dt className="sr-only">Address: </dt>
            <dd className="inline">
              <a
                href={`mailto:${config.address.googleMaps.link}`}
                className="hover:underline group"
              >
                {config.address.streetAddress},{' '}
                <span className="inline-block group-hover:underline">
                  {config.address.suburb}
                </span>
              </a>
            </dd>
          </div>
        </dl>
      </div>
      <div>
        <h3 className="text-xl font-semibold">Follow Us On</h3>
        {config.socialLinks.map(({ id, link, socialNetwork }) => {
          const Icon = ICON_MAP[socialNetwork];
          return (
            <a
              key={id}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block mt-1 transition duration-150 ease-in-out hover:opacity-75"
            >
              <span className="sr-only">{socialNetwork}</span>
              <Icon aria-hidden className="w-8 h-8" />
            </a>
          );
        })}
      </div>
    </div>
  );
}

export { ContactDetails };
