import * as React from 'react';

function CategoryIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" width={43} height={43} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.134 3.583h6.056c2.526 0 4.55 2.06 4.55 4.589v6.107c0 2.545-2.024 4.587-4.55 4.587H8.134c-2.508 0-4.551-2.042-4.551-4.586V8.172c0-2.528 2.043-4.589 4.55-4.589zm0 20.55h6.056c2.526 0 4.55 2.044 4.55 4.589v6.107c0 2.527-2.024 4.587-4.55 4.587H8.134c-2.508 0-4.551-2.06-4.551-4.587v-6.107c0-2.545 2.043-4.589 4.55-4.589zm26.732-20.55H28.81c-2.526 0-4.551 2.06-4.551 4.589v6.107c0 2.545 2.025 4.587 4.55 4.587h6.057c2.508 0 4.55-2.042 4.55-4.586V8.172c0-2.528-2.042-4.589-4.55-4.589zm-6.056 20.55h6.056c2.508 0 4.55 2.044 4.55 4.589v6.107c0 2.527-2.042 4.587-4.55 4.587H28.81c-2.526 0-4.551-2.06-4.551-4.587v-6.107c0-2.545 2.025-4.589 4.55-4.589z"
      />
    </svg>
  );
}

export { CategoryIcon };
