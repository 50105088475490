import '@reach/dialog/styles.css';

import { Dialog } from '@reach/dialog';
import { Link } from 'gatsby';
import * as React from 'react';

import { useSanityRooms } from '../hooks/use-sanity-site-rooms';
import { ICON_MAP } from '../lib/icon-map';

const VARIANT_MAP = {
  0: 'bg-green',
  1: 'bg-teal',
  2: 'bg-brown',
  3: 'bg-brown-light',
};

function NavRooms(): React.ReactElement {
  const rooms = useSanityRooms();

  const CategoryIcon = ICON_MAP.Category;
  const CloseIcon = ICON_MAP.Close;

  const [showDialog, setShowDialog] = React.useState(false);
  const open = (): void => setShowDialog(true);
  const close = (): void => setShowDialog(false);

  return (
    <>
      <nav className="hidden md:block">
        <ul className="space-y-4">
          {rooms.map(({ id, title, slug }, i) => {
            return (
              <li key={id}>
                <Link
                  className={`px-12 block py-8 font-semibold text-white rounded-l-full text-lg ${
                    VARIANT_MAP[i] as string
                  }`}
                  to={`/rooms/${slug.current}/`}
                >
                  {title} Room
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>

      <div className="flex items-start h-full pt-12">
        <button
          type="button"
          onClick={open}
          className="block px-2 py-2 rounded-l-full md:hidden bg-green"
        >
          <span className="sr-only">Open Childcare rooms menus</span>
          <CategoryIcon aria-hidden className="transform scale-75 " />
        </button>
      </div>

      <Dialog
        aria-label="Rooms navigation"
        isOpen={showDialog}
        onDismiss={close}
      >
        <button
          type="button"
          className="absolute top-0 right-0"
          onClick={close}
        >
          <span className="sr-only">Close Childcare rooms menus</span>
          <CloseIcon aria-hidden className="w-6 h-6 text-white" />
        </button>
        <ul className="space-y-4">
          {rooms.map(({ id, title, slug }, i) => {
            return (
              <li key={id}>
                <Link
                  className={`px-12 block py-4 text-center font-semibold text-white rounded-full text-lg ${
                    VARIANT_MAP[i] as string
                  }`}
                  to={`/rooms/${slug.current}/`}
                >
                  {title} Room
                </Link>
              </li>
            );
          })}
        </ul>
      </Dialog>
    </>
  );
}

export { NavRooms };
