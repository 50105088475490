import * as React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

type TextareaProps = {
  name: string;
  label: string;
  rows?: number;
  errors: DeepMap<Record<string, unknown>, FieldError>;
  props?: unknown[];
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ name, label, rows = 8, errors, ...props }, ref) => {
    const hasErrors = Boolean(errors?.[name]);
    return (
      <div>
        <label htmlFor={name} className="block">
          <span className="sr-only">{label}</span>
          <textarea
            id={name}
            name={name}
            rows={rows}
            placeholder={label}
            ref={ref}
            className="block w-full border-transparent focus:ring-blue-500 focus:ring-opacity-50 focus:border-blue-500 focus:border-opacity-50"
            {...props}
          />
        </label>
        {hasErrors ? (
          <div
            role="alert"
            className="mt-1 text-xs font-semibold tracking-widest text-red-800 uppercase"
          >
            {label} is a required field
          </div>
        ) : null}
      </div>
    );
  }
);

export { Textarea };
